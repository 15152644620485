<template>
    <div class="search_filter">
        <div>
            <div class="sf_top">
                <div class="sf_title">카테고리</div>
                <el-select class="sf_select" value="all" interface="popover">
                    <el-option value="all">전체</el-option>
                </el-select>
            </div>
            <div class="sf_top ml15">
                <div class="sf_title">진행상태</div>
                <el-select class="sf_select" value="all" interface="popover">
                    <el-option value="all">전체</el-option>
                    <el-option value="all2">ㅇㅇ</el-option>
                    <el-option value="all3">ㅇㅇ</el-option>
                </el-select>
                <div class="clear"></div>
            </div>
            <div class="sf_middle">
                <div class="sf_title">기관</div>
                <el-select class="sf_select" value="all" interface="popover">
                    <el-option value="all">전체</el-option>
                </el-select>
                <div class="clear"></div>
            </div>
            <div class="sf_bottom">
                <el-select class="sf_select sm_select" value="all" interface="popover">
                    <el-option value="all">제목</el-option>
                </el-select>
                <div  class="sf_select sf_item pa0">
                    <input class="sf_input search_input" placeholder="검색어를 입력하세요"></input>
                    <button class="sf_btn">검색</button>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>
<!--dd-->
<script>
    import EventBus from "@/utils/event-bus";

    export default {
        name: "BoardFilterLetterLayout",
        props: ['filterData', 'setData'],

        data() {
            return {
                selectCate: '',
            }
        },
        computed: {
            categorys() {
                return this.filterData.hasOwnProperty('categorys') ? this.filterData.categorys : [];
            },
            categorysLength() {
                this.$forceUpdate()
                return this.filterData.hasOwnProperty('categorys') ? this.filterData.categorys.length : 0;
            },
        },
        mounted() {

        },
        methods: {
            getCategorys() {
                if (this.filterData.hasOwnProperty('categorys')) {
                    return this.filterData.categorys
                }
                return []
            },
            selectCategoryFunc(idx) {
                this.selectCate = idx;
                let filterData = this.filterData;
                filterData.filter.fc_idx = this.selectCate;
                this.setData('filterData', filterData);
                EventBus.$emit('getBoardList', true)
            },
            hasActive(idx) {
                if (this.selectCate === idx) {
                    return 'active';
                }
                return '';
            },

        },
        watch: {},

    }
</script>

<style scoped>

</style>